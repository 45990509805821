import React from 'react'
import { Link } from 'react-router-dom';
import '../Pages/search.css';

let SetType = null;
let CurrentMonth = null;
let CurrentYear = null;

let callbackState = null;

const SetStoryType = function(selected_type) {
  SetType = selected_type;
  CurrentMonth = 0;
  CurrentYear = 0;
}

const SetHook = function(hook) { callbackState = hook }

const GetJSON = async function (search_type)
{
    const target = `${window.location.origin}/json/${search_type}.json`
    
    const headers = { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

   return fetch(target, headers)
    .then(res => res.json())
    .then(r => r);
}

const GetRender = function(data, loaded, year, month)
{

    const paddingStyle = {'paddingTop':'20px'};
    const textTransform = {"textTransform": "capitalize"}
    let availableYears = [] 
    
    if(data)
    {
      availableYears = Object.keys(data).sort( function ( a, b ) { return b - a; } );
    }                        

    if(availableYears.length < 1)
    {
      return (
        <div className="container" style={paddingStyle}>      
            <div>
              <h2 className="title">Search</h2>
              <h2 className="title is-5" style={textTransform}>Searching by {SetType}</h2>
            </div>                     
            <br/>
            <p>loading</p>
        </div> 
      )
    }

    return (
        <div className="container" style={paddingStyle}>      

        <div>
          <h2 className="title">Search</h2>
          <h2 className="title is-5" style={textTransform}>Searching by {SetType}</h2>
        </div>   
            
        <br/>

        <div class="columns">
          <div class="column is-one-fifth">
            <aside class="menu">
              <p class="menu-label">
                  Available Years
              </p>
              <ul class="menu-list" id="years">
              {
                Object.keys(availableYears).map((key, index) => ( 
                  <li><a className={( index === 0 ) ? "is-active" : null } onClick={() => ChangeSelectedYear(index) }>{availableYears[key]}</a></li>
                ))
              }
              </ul>           
            </aside>
          </div>
          {
            GetYearPanel(data, availableYears[year], month)
          }         
        </div>
        <br />
      </div>    
    )
}

const GetYearPanel = function (data, year, month)
{
    if(!data) throw new Error("Data returned is empty, this is an issue with the JSON request");

    let stories = data[year];

    let AvailableDates = []
    let month_number = -1;

    stories.forEach(element => {
        month_number = element['date'][0]['month']
        if(!AvailableDates.includes(month_number))
          AvailableDates.push(month_number)
    });

    const Months = {
        1: 'Jan', '01': 'Jan',
        2: 'Feb', '02': 'Feb',
        3: 'Mar', '03': 'Mar',
        4: 'Apr', '04': 'Apr',
        5: 'May', '05': 'May',
        6: 'Jun', '06': 'Jun',
        7: 'Jul', '07': 'Jul',
        8: 'Aug', '08': 'Aug',
        9: 'Sep', '09': 'Sep',
        10: 'Oct',11: 'Nov', 12: 'Dec'
    }
    
    AvailableDates.sort( function ( a, b ) { return a - b; } );
    
    let CompareDate = AvailableDates[month]
    
    return (
    <div class="column">
      <nav class="panel">
          <p class="panel-heading" style={{"text-transform": "capitalize"}}>
              Available {SetType}'s ({year})
          </p>
          <div class="panel-block">
              <p class="control has-icons-left">
                  <input class="input" type="text" placeholder="Search" onChange={SearchChange} />
                  <span class="icon is-left">
                  <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
              </p>
          </div>
          <p class="panel-tabs" id="dates">
          {
              AvailableDates.map( (m, index) => (
              <a className={(index === 0) ? "is-active" : null } onClick={() => ChangeMonth(index, year)}>{Months[m]}</a>
              ))
          }
          </p>
          {
          stories.map(d => (
              <a className={(d['date'][0]['month'] === CompareDate) ? "panel-block" : "panel-block panel-hidden"} 
                  id="target">
                  <Link to={`/view/${d.id}`}>{d.title}</Link>            
              </a>
          ))               
          }
          <a id="not_found" className={"panel-block panel-hidden"}>
              <p>NOT FOUND</p>
          </a>
      </nav>
  </div>)
}

const SetYear = function (year) 
{
    let currentYears = document.querySelector("#years")
    currentYears.children[CurrentYear].children[0].classList.toggle('is-active')
    currentYears.children[year].children[0].classList.toggle('is-active')
    CurrentYear = year
}
      
const SetMonth = function (month) 
{
    let dates = document.querySelector("#dates")
    dates.children[CurrentMonth].classList.toggle('is-active')
    dates.children[month].classList.toggle('is-active')
    CurrentMonth = month
}

const ChangeMonth = function(month, year)
{
    // Set the files to the new month to load
    SetMonth(month)

    InvokeDateUpdate()
}

const ChangeSelectedYear = function(year)
{
    SetYear(year)

    InvokeDateUpdate()
}

const InvokeDateUpdate = function()
{
    callbackState(CurrentMonth, CurrentYear)
}

const SearchChange = function (e)
{
    //console.log("target", e)

    const filter_out_class = 'panel-hidden'
    const force_hidden_class = 'panel-force-hide'
    const force_shown_class = 'panel-force-show'

    let resetFilter_forceHidden = Array.from(document.getElementsByClassName(force_hidden_class));
    let resetFilter_forceShown = Array.from(document.getElementsByClassName(force_shown_class));

    if(resetFilter_forceHidden.length > 0) resetFilter_forceHidden.forEach( t => { t.classList.remove(force_hidden_class) })

    if(resetFilter_forceShown.length > 0) resetFilter_forceShown.forEach( t => { t.classList.remove(force_shown_class) })

    if(e.target.value.length === 0) 
    {
        document.getElementById('not_found').classList.add(filter_out_class)
        document.getElementById('not_found').innerHTML = ""
        return;
    }

    // Get all the articles from that year (including hidden ones)
    let availableStories = Array.from(document.querySelectorAll("#target"))
    // Then only return back the visible stories that we want to filter
                            .filter(x => { return !x.classList.contains(filter_out_class); })
                
    // validate if its worth while
    if(availableStories.length > 0)
    {
        document.getElementById('not_found').classList.add(filter_out_class)
        availableStories.map(x => x.classList.add(force_hidden_class))
        
        // Now filter based on text result
        var any_matches = availableStories.filter(x => { return x.innerText.toLowerCase().startsWith(e.target.value.toLowerCase())})

        if(any_matches.length > 0)
        {
            //panel-force-show
            any_matches.map(x => {
                x.classList.remove(force_hidden_class)
                x.classList.add(force_shown_class)
            })
            return;
        }
    }
    
    document.getElementById('not_found').classList.remove(filter_out_class)
    document.getElementById('not_found').innerHTML = `No ${SetType}'s with title:&emsp;<b>${e.target.value}</b>`
}

export { GetJSON, GetRender, GetYearPanel, SetYear, SetMonth, SearchChange, SetStoryType, SetHook}