import React from 'react'

const Quotes = () => {
    return(
        <div className="container">
            <h4 className="title" style={{"padding-top": "20px"}}>Quotes</h4>
            <br></br>
            <img src={"/Images/quotes/dr-henderson-quote-humble.jpg"}/>
        </div>     
    )
}

export default Quotes