import React from 'react'

import { Link, NavLink } from 'react-router-dom'

const Navbar = (props)  => {
    return (

        <nav class={"navbar has-shadow is-white is-fixed-top"} role="navigation" aria-label="main navigation">
        <div className={"container"}>
        <div class="navbar-brand">
          <a class="navbar-item">
          <Link to="/" class="navbar-item title is-5" style={{"margin-bottom": 0 }}>Dr. Henderson Lightbody</Link>    
          </a>
        
        <a class="navbar-burger" data-target="nav-menu-main" id="burger">
          <span></span>
          <span></span>
          <span></span>
        </a>
       </div>
        <div id="nav-menu-main" class="navbar-menu">
          <div class="navbar-start">
            <NavLink to='/about' className={ "navbar-item" }>About</NavLink>
              <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">Stories</a>
                <div class="navbar-dropdown is-boxed">
                  <Link to={"/articles"} className={ "navbar-item" }>Articles</Link>
                  <Link to={"/poems"} className={ "navbar-item" }>Poems</Link>
                  <Link to={"/quotes"} className={ "navbar-item" }>Quotes</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

    )
}

export default Navbar