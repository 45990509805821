import React from 'react'
import './css/home.css'

const available_videos = [
    "C2q4gr_87u4",
    "2jEIuRAuTtPM",
    "WWR40JP0V_c",
    "E45wOYEBBgk",
    "BG4t42LBdB4",
    "EJEAaN-_Os4",
    "7FYpQGOabeg",
    "ORYEzkwwBJQ",
    "aS_p6_OtFp0",
    "cUZlS3Pmdxk",
    "Sa8VtYg0qyg",
    "QGMJfGRj2As",
    "Nblf0R5Zs7Q"
]

const ShowYoutubeVideo = function (url) {
    return(<iframe className={"iframe_size"}
    src={`https://www.youtube.com/embed/${url}`} 
    title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>)
}

const Home = () => {
    return(
        <div className="container paddingV-20">
            <p className={"subtitle is-4"}>Welcome to <b>Dr. Henderson Lightbody's Website!</b></p>
            <br></br>
            <p className={"subtitle is-6"}>Dr. Henderson has written lots of articles and poems where you can view from the navigation bar above.</p>
            <p className={"subtitle is-6"}>Feel free to look around at his works.</p>
            <br></br>
            <h3>Here is an example video from <b>Dr. Henderson Lightbody's</b> youtube:</h3><br></br>
            <div class="centreText">{ShowYoutubeVideo(available_videos[Math.floor(Math.random() * available_videos.length)])}</div>
        </div>     
    )
}

export default Home