import React from 'react'
//import {GetJSON} from '../Components/searcher';

//import {Link} from 'react-router-dom';
import './search.css';
const _type = 'poem'
const { GetJSON, GetRender, SetStoryType, SetHook  } = require('../Components/searcher');

class Poems extends React.Component {
    constructor(props) 
    { 
        super(props);
        this.state = {
            data: null,
            loaded: false,
            type: _type,
            currentSelectedYear: 0, currentSelectedMonth: 0
        }
        this.updateSelf = this.updateSelf.bind(this)
        SetHook(this.updateSelf)
        SetStoryType(_type);
    }

    updateSelf(m, y) 
    {
        //console.log(m, y)
        this.setState({currentSelectedYear: y, currentSelectedMonth: m})
    }

    async componentDidMount() {
       GetJSON(_type).then(r => { this.setState({data: r, loaded: r !== undefined})});
    }

    render()
    {
        if(!this.state.loaded)
        {
            GetRender( null, false);        
        }

        //console.log("state loaded", this.state.loaded, this.state.data)
        return GetRender(this.state.data, this.state.loaded, this.state.currentSelectedYear, this.state.currentSelectedMonth)
    }
}

export default Poems