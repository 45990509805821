import React from 'react'
//import {devLog} from '../utils'

class Story extends React.Component {

    constructor(props) {
        super(props);
        
        //let { id } = useParams();
        //console.log(props)

        this.state = {
          id: window.location.pathname.split("/").pop(),
          isLoaded: false,
          err: null,
          error_number: 0
        };
        this.gap = {'padding-top':'20px'};
        this.data = null;
        this.ShowMessageDialog = this.ShowMessageDialog.bind(this);
    }

    componentDidMount() {
        const json_location = `${window.location.origin}/Documents/${this.state.id}.json`
        
        //devLog(json_location)

        fetch(json_location)
        .then(res => {

            if (res.status === 404)
            {
                this.setState({error_number: res.status})
                return Promise.reject('NF')
            }

            return res.json()
        })
        .then(result => {
          this.data = result;
          this.setState({isLoaded: true})
        }).catch(json_error => {
            //devLog(`Error with -> ${json_error}`)
            this.setState({isLoaded: false, err: json_error})
        });
    }

    ShowMessageDialog(pdf, source) {
        if(!pdf || !source)
        {
            let text = "";

            if(!pdf)
                text += "This doesn't contain any viewable PDFs as reference"
            
            if(!source)
            {
                text += (text.length === 0) ? "This doesn't contain any external sources as its reference" : "\nThis doesn't contain any external sources as its reference" 
            }
                

            return (           
                <div class="notification is-warning">
                    <p>{text}</p>
                </div>
            )
        }
    }

    render() {        

        const renderLoad = () => {
            if (this.state.err !== null) {

                const reason_title = (this.state.err === "NF") ? "Page not found on server" : "Fault in server end"
                const reason_help = (this.state.err === "NF") ? "The attempted story you want to view has been moved or does not exist anymore" : "An unexpected fault happened while attempting to get the story information"
              return (
                <article class="message is-danger" style={{'marginBottom': '20px'}}>
                    <div class="message-header">
                        <p>Fault from Server</p>
                    </div>
                    <div class="message-body">
                        <h2><b>{reason_title}</b></h2>
                        <br></br>
                        
                        <p className={'subtitle is-4'}>{reason_help}</p>
                        <br/>

                        <p>Server Debug Information:</p>
                        <p>response code: {this.state.error_number}</p>
                        <p>request for: {this.state.id}</p>
                        <p>promise return fault: {this.state.err}</p>
                        <br/>
                        <p>Please email the contacts in the footer (below) the server debug information to resolve this issue - Thank you.</p>
                    </div><br/>
                </article>
                
              )
            } else {
              return <p>Loading... please waiting for response for server.</p>
            }
          }

        if(!this.state.isLoaded)
        {
            return (
                <div className="container" style={this.gap}>                          
                    {renderLoad()}
                </div>   
            )
        }
        
        const HasPDF = this.data["pdf"] !== undefined
        const HasSource = this.data["source"] !== undefined

        const disable_tag = (HasPDF) ? 'disabled' : null
        const disable_tag_s = (HasSource) ? 'disabled' : null

        let text_dialog = null;

        let no_text = true


        try {
            no_text = this.data["text"].length === 0
        } catch {
            no_text = this.data["text"] === undefined
        }

        if(no_text)
        {
            text_dialog = <p>There is no available text for this yet, please wait until written completely.<br/><br/>For now, please use the 'View PDF' or 'View Source' buttons to read this post.</p>
        } else {
            
            text_dialog = []
            var currentLine = null
            let divElements = []

            // Check for single line only
            if (this.data.text.length === 1)
            {
                divElements.push(<p className={'subtitle is-5'}>{this.data.text[0]}</p>)
                text_dialog.push(React.createElement('div', {style: {paddingTop: '35px', paddingBottom: '35px'}}, divElements))
            }
            else {

                for(const paragraph in this.data["text"])
                {
                    let element_style = {}
                    
                    for (const line in this.data["text"][paragraph])
                    {
                        currentLine = this.data["text"][paragraph][line]
                        if(currentLine.startsWith('<'))
                        {
                            const element = document.createRange().createContextualFragment(currentLine);
                            
                            const styling = element.firstChild.style.cssText.split("")
    
                            var currentKey = null
                            
                            var pendingKey = false
                            var strBuilder = []
    
                            const arrLength = styling.length - 1
    
                            for(const chara in styling)
                            {
                                if(!pendingKey && styling[chara] !== ':')
                                { 
                                    strBuilder.push(styling[chara]); continue; 
                                }
                                else if (pendingKey)
                                {
                                    strBuilder.push(styling[chara]); 
                                    
                                    if(chara < arrLength || styling[chara] !== ';')
                                        continue;
    
                                    let value = strBuilder.join("").trim();
    
                                    if (value.includes(';'))
                                    value = value.replace(';','')
    
                                    element_style[currentKey] = value
    
                                    //currentKey = null
                                    strBuilder = []
                                    pendingKey = false
                                    continue;
                                }
    
                                pendingKey = true;
    
                                currentKey = strBuilder.join("").trim()
                                element_style[currentKey] = null
                                strBuilder = []
                            }
    
                            
                            divElements.push(React.createElement(element.firstChild.nodeName.toLowerCase(), {className: element.firstChild.className, style: element_style }, element.firstChild.innerHTML))
                        }
                        else
                            divElements.push(<p className={'subtitle is-5'}>{currentLine}</p>)
                    }
                    text_dialog.push(React.createElement('div', {style: {paddingTop: '35px', paddingBottom: '35px'}}, divElements))
                    divElements = []
                }
                currentLine = null
            }
        }


        return(
            <div className="container" style={this.gap}>         
                <h2 className="title">{this.data.title}</h2>
                <h2 className={"subtitle is-4"}>{this.data.subheading}</h2>
                <h2 className={"subtitle is-5"}><b>{this.data.date}</b></h2>
                {this.ShowMessageDialog(HasPDF, HasSource)}<br/>      
                <div>
                    <a href={(HasPDF) ? `${window.location.origin}/PDF/${this.data.pdf}` : null} className={"button is-info is-rounded"} disabled={!disable_tag}>View PDF</a>
                    <span style={{'padding': '10px'}}></span>
                    <a href={this.data.source} className={"button is-info is-rounded"} disabled={!disable_tag_s}>View Source</a>
                </div><br/>
                {text_dialog}
                <br/>    
            </div>    
        )
    }
}

export default Story;