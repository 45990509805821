//import logo from './logo.svg';

import './Pages/css/App.css';
import "./../node_modules/bulma/css/bulma.css";
//import ReactGA from 'react-ga';
import { Route, Routes } from 'react-router-dom'

import Navbar from './Components/navbar';
import Footer from './Components/footer'

import Home from './Pages/Home'
import About from './Pages/About'
import Story from './Pages/Story'
import Poem from './Pages/Poems'
import Articles from "./Pages/Articles";
import Quotes from './Pages/Quotes';

//Google Analytics for data tracking
//ReactGA.initialize("G-WXRDD1RQ5H");

function App() {
  return (
    <div className="App">
       <Navbar/>
       <Routes>
          <Route path='/' exact element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/poems' element={<Poem/>}/>
          <Route path='/quotes' element={<Quotes/>}/>
          <Route path='/articles' element={<Articles/>}/>
          <Route path='/view/:id' element={<Story/>}/>
        </Routes>
        <div class="container card">
          <Footer />
        </div>
    </div>
  );
}

export default App;
